.App {
  height: 100vh;
  background: url("../public/background.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100vh;
  position: relative;
  top: 0;
  left: 0;
}
.betzone_2 {
  position: absolute;
  width: 7%;
  height: 8%;
  top: 39%;
  left: 16.5%;
  display: inline-block;
  cursor: pointer;
  border-radius: 30%;
}
.betzone_3 {
  position: absolute;
  width: 7%;
  height: 7%;
  top: 49%;
  left: 13.5%;
  display: inline-block;
  cursor: pointer;
  border-radius: 30%;
}
.betzone_4 {
  position: absolute;
  width: 7%;
  height: 7%;
  top: 58.5%;
  left: 16.5%;
  display: inline-block;
  cursor: pointer;
  border-radius: 30%;
}
.betzone_5 {
  position: absolute;
  width: 7%;
  height: 7%;
  top: 63.5%;
  left: 26%;
  display: inline-block;
  cursor: pointer;
  border-radius: 30%;
}
.betzone_6 {
  position: absolute;
  width: 7%;
  height: 7%;
  top: 62.3%;
  left: 36.8%;
  display: inline-block;
  cursor: pointer;
  border-radius: 30%;
}
.betzone_8 {
  position: absolute;
  width: 7%;
  height: 7%;
  top: 62.3%;
  left: 56.2%;
  display: inline-block;
  cursor: pointer;
  border-radius: 30%;
}
.betzone_9 {
  position: absolute;
  width: 7%;
  height: 7%;
  top: 63.5%;
  left: 67%;
  display: inline-block;
  cursor: pointer;
  border-radius: 30%;
}
.betzone_10 {
  position: absolute;
  width: 7%;
  height: 7%;
  top: 58.5%;
  left: 76.3%;
  display: inline-block;
  cursor: pointer;
  border-radius: 30%;
}
.betzone_11 {
  position: absolute;
  width: 7%;
  height: 7%;
  top: 49%;
  left: 79.5%;
  display: inline-block;
  cursor: pointer;
  border-radius: 30%;
}
.betzone_12 {
  position: absolute;
  width: 7%;
  height: 7%;
  top: 39.5%;
  left: 76.4%;
  display: inline-block;
  cursor: pointer;
  border-radius: 30%;
}
.betzone_u7 {
  position: absolute;
  width: 10%;
  height: 10%;
  top: 43%;
  left: 31%;
  display: inline-block;
  cursor: pointer;
  border-radius: 30%;
}
.betzone_o7 {
  position: absolute;
  width: 10%;
  height: 10%;
  top: 43%;
  left: 59.3%;
  display: inline-block;
  cursor: pointer;
  border-radius: 30%;
}
.betzone_7 {
  position: absolute;
  width: 10%;
  height: 10%;
  top: 45%;
  left: 45%;
  display: inline-block;
  cursor: pointer;
  border-radius: 30%;
}

.bottom_chips {
  position: fixed;
  bottom: 7%;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.bottom_buttons {
  position: fixed;
  bottom: 1%;
  color: white;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 3rem;
}
.bottom_buttons > img {
  width: 90px;
  height: 90px;
}
.bottom_buttons > div {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  color: burlywood;
}

.button_undo,
.button_throw,
.button_double,
.button_clear {
  width: 90px;
  height: 90px;
  background: url("../public/buttons/undo.png");
  background-repeat: no-repeat;
  background-size: 90px 90px;
  cursor: pointer;
}
.button_throw {
  background: url("../public/buttons/throw.png");
}
.button_double {
  background: url("../public/buttons/double.png");
}
.button_clear {
  background: url("../public/buttons/clear.png");
}
.button_undo:hover {
  background: url("../public/buttons/undo_push.png");
}
.button_throw:hover {
  background: url("../public/buttons/throw_push.png");
}
.button_double:hover {
  background: url("../public/buttons/double_push.png");
}
.button_clear:hover {
  background: url("../public/buttons/clear_push.png");
}
.chip_container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.chip_container_selected {
  transform: translateY(-30px);
  transition: 0.3s;
  display: flex;
  cursor: pointer;
  flex-direction: column;
}
.chip_container > span,
.chip_container_selected > span {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  position: relative;
  color: white;
  top: -60px;
  pointer-events: none;
}
.chip_container_selected > span {
  top: -63px;
}
.chip_container > img {
  width: 81px;
  /* height: 81px; */
}
.chip_container_selected > img {
  width: 90px;
  height: 90px;
}
.white_chip > span {
  color: black;
}

.top_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}
.left_header,
.right_header {
  display: flex;
  width: 40%;
  padding: 1rem;
  padding-top: 0.3rem;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  color: burlywood;
}
.right_header {
  justify-content: space-between !important;
}
.right_clock {
  min-width: 150px;
  font-size: 1rem;
}
.right_icons {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 3rem;
  cursor: pointer;
}
.right_icon > img {
  width: 51px;
  height: 51px;
}
.left_history {
  cursor: pointer;
}

.betzone_2_chips,
.betzone_3_chips,
.betzone_4_chips,
.betzone_5_chips,
.betzone_6_chips,
.betzone_8_chips,
.betzone_9_chips,
.betzone_10_chips,
.betzone_11_chips,
.betzone_12_chips,
.betzone_100_chips,
.betzone_101_chips,
.betzone_102_chips {
  position: absolute;
  width: 60px;
  height: 60px;
  top: 39%;
  left: 23%;
}
.betzone_3_chips {
  top: 48%;
  left: 20%;
}
.betzone_4_chips {
  top: 56%;
  left: 22.5%;
}
.betzone_5_chips {
  top: 61%;
  left: 32%;
}
.betzone_6_chips {
  top: 59.5%;
  left: 42%;
}
.betzone_8_chips {
  top: 59.5%;
  left: 54.5%;
}
.betzone_9_chips {
  top: 61%;
  left: 65%;
}
.betzone_10_chips {
  top: 56%;
  left: 74.5%;
}
.betzone_11_chips {
  top: 48%;
  left: 77%;
}
.betzone_12_chips {
  top: 39%;
  left: 74%;
}
.betzone_100_chips {
  top: 41%;
  left: 30%;
}
.betzone_101_chips {
  top: 44%;
  left: 44%;
}
.betzone_102_chips {
  top: 41%;
  left: 58%;
}
.betzone_2_chips > img,
.betzone_3_chips > img,
.betzone_4_chips > img,
.betzone_5_chips > img,
.betzone_6_chips > img,
.betzone_8_chips > img,
.betzone_9_chips > img,
.betzone_10_chips > img,
.betzone_11_chips > img,
.betzone_12_chips > img,
.betzone_100_chips > img,
.betzone_101_chips > img,
.betzone_102_chips > img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.betzone_2_chips > div,
.betzone_3_chips > div,
.betzone_4_chips > div,
.betzone_5_chips > div,
.betzone_6_chips > div,
.betzone_8_chips > div,
.betzone_9_chips > div,
.betzone_10_chips > div,
.betzone_11_chips > div,
.betzone_12_chips > div,
.betzone_100_chips > div,
.betzone_101_chips > div,
.betzone_102_chips > div {
  width: 60px;
  text-align: center;
  font-size: 0.81rem;
  font-weight: 500;
  position: relative;
  color: white;
  top: -45px;
  pointer-events: none;
}
.white_chip {
  color: black !important;
}

.dropdown_root {
  position: absolute;
  width: 9.3%;
  top: 7.3%;
  z-index: 2;
}
.logoff_dropdown {
  position: absolute;
  width: 9.3%;
  top: 7.3%;
  left: 90.6%;
  z-index: 2;
}
.mobile_drop_root {
  position: absolute;
  width: 9.3%;
  top: 21%;
  z-index: 2;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 12px;
}
.mobile_drop_list {
  margin-left: 0;
  height: 90vh;
  background-color: rgba(0, 0, 0, 0.72);
}
.dropdown_item {
  padding: 6px 9px;
  background-color: rgba(255, 255, 255, 0.6);
  text-align: center;
  margin-bottom: 9px;
  border-radius: 6px;
  z-index: 12299;
}
.mobile_drop_item {
  padding: 3px 3px;
  background-color: rgba(255, 255, 255, 0.66);
  color: black;
  text-align: center;
  margin-bottom: 9px;
  border-radius: 3px;
  z-index: 12299;
  font-size: 0.81rem;
}
.dropdown_item:hover {
  cursor: pointer;
  background-color: rgba(66, 96, 39, 0.9);
}
.dropdown_res {
  font-size: 0.6vw;
}

.res_dice {
  margin-left: 2px;
  margin-bottom: -3px;
}
.modalhist_root {
  position: fixed;
  z-index: 12999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: none;
  background-color: rgba(0, 0, 0, 0.66);
}
.modal_hist_wrap {
  width: 600px;
  height: 600px;
  max-width: 600px;
  border-radius: 4px;
  padding: 32px 40px;
  margin-left: 618px;
  margin-top: 108px;
  position: relative;
  border: none;
  outline: none;
  color: white;
}
.history_title {
  display: flex;
  text-align: center;
  font-size: 141%;
  font-weight: 400;
  margin-bottom: 12px;
}
.hist_lbl {
  width: 90%;
}
.history_list {
  margin-top: 6px;
  max-height: 560px;
  overflow: auto;
}
.history_list::-webkit-scrollbar {
  width: 7px;
  background-color: #282828;
}
.history_list::-webkit-scrollbar-track {
  background-color: rgba(51, 51, 51, 0.63);
}
.history_list::-webkit-scrollbar-thumb {
  background-color: rgba(66, 96, 39, 0.9);
}
.history_list_titles {
  display: flex;
  width: 100%;
  margin-bottom: 6px;
  margin-top: 3px;
}

.history_single_root {
  padding: 3px;
  cursor: pointer;
  color: gray;
  display: flex;
}
.hist_act {
  color: white;
}
.history_single_root:hover {
  color: white;
}

.label_round,
.label_date,
.label_stake,
.label_win {
  width: 25%;
  text-align: center;
}
.label_round_s {
  width: 23%;
  margin-left: 2%;
}
.label_date_s {
  width: 27%;
}
.label_stake_s {
  width: 17%;
  text-align: right;
}
.label_win_s {
  width: 25%;
  text-align: right;
}

.history_details_gen {
  padding: 24px;
}
.details_old_bal,
.details_new_bal {
  margin-left: 36px;
}
.details_old_bal span {
  font-size: 81%;
  margin-right: 12px;
}
.details_new_bal span {
  font-size: 81%;
  margin-right: 6px;
}
.details_round_details,
.details_each_bet {
  margin-left: 36px;
  display: flex;
}
.round_det_dices {
  font-size: 81%;
  margin-top: 6px;
  width: 30%;
}
.round_det_dices span {
  margin-right: 45px;
}
.res_dice_sg {
  margin-bottom: -3px;
  margin-right: 3px;
}
.round_det_stake {
  width: 30%;
  margin-top: 6px;
}
.round_det_stake span {
  font-size: 81%;
  margin-right: 6px;
}
.details_each_bet {
  margin-top: 12px;
}

.each_zone span {
  font-size: 81%;
  color: white;
  font-weight: 300;
  margin-right: 12px;
}
.each_zone,
.stake_zone,
.win_zone {
  color: white;
  width: 30%;
}
.close_button {
  cursor: pointer;
}
.stake_zone span {
  font-size: 81%;
  color: white;
  font-weight: 300;
  margin-right: 33px;
}
.win_zone span {
  font-size: 81%;
  color: white;
  font-weight: 300;
  margin-right: 33px;
}
.modal_frameup {
  position: absolute;
  height: 12px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 6;
}
.modal_framedown {
  position: absolute;
  height: 12px;
  width: 100%;
  left: 0;
  top: 98%;
  z-index: 6;
}
.modal_frameleft {
  position: absolute;
  width: 12px;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 6;
}
.modal_frameright {
  position: absolute;
  width: 12px;
  top: 0;
  left: 98.5%;
  height: 100%;
  z-index: 6;
}
.corder_left_down {
  position: absolute;
  width: 13px;
  height: 15px;
  left: 0;
  top: 97.7%;
  z-index: 6;
}
.corder_left_up {
  position: absolute;
  width: 13px;
  height: 15px;
  left: 0;
  top: 0;
  z-index: 6;
}
.corder_right_down {
  position: absolute;
  width: 13px;
  height: 15px;
  top: 97.7%;
  left: 98.2%;
  z-index: 6;
}
.corder_right_up {
  position: absolute;
  width: 13px;
  height: 15px;
  top: 0;
  left: 98.2%;
  z-index: 6;
}
body > div.splash_modal > div:nth-child(1) {
  background-color: transparent !important;
}
.mobile_top_header,
.mobile_left_burger,
.mobile_right_fullscreen,
.play_buttons_mobile {
  display: none;
}

.please_change_root {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  z-index: 123111;
}
.please_change_header {
  width: 100%;
  height: 30%;
  text-align: center;
  font-size: 4.8vw;
  line-height: 12;
  font-weight: 600;
}
.please_change_gif {
  width: 100%;
  height: 30%;
}
.rotate_gif {
  width: 50%;
  height: 80%;
  margin-left: 25%;
}
.please_change_info {
  width: 100%;
  height: 40%;
  text-align: center;
  font-size: 3vw;
}

@media all and (max-width: 1200px) {
  .App {
    background: url("../public/empty_table_back_mobile.png");
    background-size: 100% 100vh;
    background-repeat: no-repeat;
  }
  .top_header {
    display: none;
  }
  .mobile_top_header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4.2rem;
    padding-top: 4.2%;
    color: lightgray;
    font-size: 0.72rem;
  }
  .mobile_left_burger {
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
    width: 51px;
    height: 51px;
  }
  .mobile_right_fullscreen {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 51px;
    height: 51px;
  }
  .mobile_left_burger > img,
  .mobile_right_fullscreen > img {
    width: 100%;
    height: 100%;
  }

  .betzone_2 {
    top: 30%;
  }
  .betzone_3 {
    top: 41.5%;
  }
  .betzone_4 {
    top: 52%;
  }
  .betzone_5 {
    top: 58%;
  }
  .betzone_6 {
    top: 57%;
  }
  .betzone_8 {
    top: 57%;
  }
  .betzone_9 {
    top: 58%;
  }
  .betzone_10 {
    top: 52%;
  }
  .betzone_11 {
    top: 41.5%;
  }
  .betzone_12 {
    top: 31%;
  }
  .betzone_u7 {
    top: 35%;
  }
  .betzone_7 {
    top: 38%;
  }
  .betzone_o7 {
    top: 35%;
  }
  .bottom_chips {
    bottom: 0px;
    max-height: 100px;
    gap: 0.21rem;
  }
  .chip_container > img {
    max-width: 54px;
  }
  .chip_container_selected > img {
    width: 54px;
    height: 54px;
  }
  .chip_container > span {
    font-size: 1rem;
    color: white;
    top: -39px;
    pointer-events: none;
  }
  .chip_container_selected > span {
    font-size: 1rem;
    top: -40px;
  }
  .white_chip > span {
    color: black;
  }

  .betzone_2_chips {
    width: 33px;
    height: 33px;
    top: 25%;
  }
  .betzone_3_chips {
    width: 33px;
    height: 33px;
    top: 37%;
  }
  .betzone_4_chips {
    width: 33px;
    height: 33px;
    top: 47%;
  }
  .betzone_5_chips {
    width: 33px;
    height: 33px;
    top: 51%;
  }
  .betzone_6_chips {
    width: 33px;
    height: 33px;
    top: 52%;
  }
  .betzone_8_chips {
    width: 33px;
    height: 33px;
    top: 52%;
  }
  .betzone_9_chips {
    width: 33px;
    height: 33px;
    top: 51%;
  }
  .betzone_10_chips {
    width: 33px;
    height: 33px;
    top: 48%;
  }
  .betzone_11_chips {
    width: 33px;
    height: 33px;
    top: 38%;
  }
  .betzone_12_chips {
    width: 33px;
    height: 33px;
    top: 25%;
  }
  .betzone_100_chips {
    width: 33px;
    height: 33px;
    top: 29%;
  }
  .betzone_101_chips {
    width: 33px;
    height: 33px;
    top: 31%;
  }
  .betzone_102_chips {
    width: 33px;
    height: 33px;
    top: 29%;
  }
  .betzone_2_chips > div,
  .betzone_3_chips > div,
  .betzone_4_chips > div,
  .betzone_5_chips > div,
  .betzone_6_chips > div,
  .betzone_8_chips > div,
  .betzone_9_chips > div,
  .betzone_10_chips > div,
  .betzone_11_chips > div,
  .betzone_12_chips > div,
  .betzone_100_chips > div,
  .betzone_101_chips > div,
  .betzone_102_chips > div {
    width: 39px;
    font-size: 0.6rem;
    top: -30px;
    left: -3px;
  }
  .bottom_buttons {
    display: none;
  }
  .play_buttons_mobile {
    display: flex;
    justify-content: space-between;
  }
  .left_play_button {
    position: absolute;
    left: 10px;
    bottom: 10px;
    display: flex;
    justify-content: center;
    gap: 6%;
  }
  .right_play_button {
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: flex;
    justify-content: center;
    gap: 6%;
  }
  .button_undo,
  .button_throw,
  .button_double,
  .button_clear {
    width: 54px;
    height: 54px;
    background: url("../public/buttons/undo.png");
    background-repeat: no-repeat;
    background-size: 54px 54px;
    cursor: pointer;
  }
  .button_throw {
    background: url("../public/buttons/throw.png");
    background-repeat: no-repeat;
    background-size: 54px 54px;
  }
  .button_double {
    background: url("../public/buttons/double.png");
    background-repeat: no-repeat;
    background-size: 54px 54px;
  }
  .button_clear {
    background: url("../public/buttons/clear.png");
    background-repeat: no-repeat;
    background-size: 54px 54px;
  }
  .button_undo:hover {
    background: url("../public/buttons/undo_push.png");
    background-repeat: no-repeat;
    background-size: 54px 54px;
  }
  .button_throw:hover {
    background: url("../public/buttons/throw_push.png");
    background-repeat: no-repeat;
    background-size: 54px 54px;
  }
  .button_double:hover {
    background: url("../public/buttons/double_push.png");
    background-repeat: no-repeat;
    background-size: 54px 54px;
  }
  .button_clear:hover {
    background: url("../public/buttons/clear_push.png");
    background-repeat: no-repeat;
    background-size: 54px 54px;
  }
}
